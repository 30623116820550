<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="dash_card_body">
                  <div class="header_section">
                    <h4 class="menu_header">Layout Settings</h4>
                  </div>
                </div>
                <hr>
              </div>
              <div class="col-lg-12">
                <div class="dash_card_body">
                  <form @submit.prevent="createLayoutConfigs">
                    <div class="row">
                      <div v-if="errors ">
                        <div class="alert alert-danger">
                          <ul>
                            <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div>
                          <div class="setting_header">
                            <h2>Theme Colour Settings</h2>
                          </div>

                          <div class="form-group setting_form_grp mt-4">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_secret"> Background Color:</label>
                              <div class="col-sm-7">

                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <div class="dash-input-group-text">
                                      <input type="color" id="primary_color" class="field-radio" name="primary-color" v-model="mainColor">
                                    </div>
                                  </div>
                                  <input type="text" class="form-control" v-model="mainColorInput" aria-label="Text input with checkbox">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_secret"> Secondary Color:</label>
                              <div class="col-sm-7">

                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <div class="dash-input-group-text">
                                      <input type="color" v-model="secondaryColor">
                                    </div>
                                  </div>
                                  <input type="text" class="form-control" v-model="secondaryColorInput" aria-label="Text input with checkbox">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_secret"> Primary Color:</label>
                              <div class="col-sm-7">
                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <div class="dash-input-group-text">
                                      <input type="color" v-model="primaryColor">
                                    </div>
                                  </div>
                                  <input type="text" class="form-control" v-model="primaryColorInput" aria-label="Text input with checkbox">
                                </div>

                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_secret"> Font Color:</label>
                              <div class="col-sm-7">

                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <div class="dash-input-group-text">
                                      <input type="color" id="color" v-model="fontColor">
                                    </div>
                                  </div>
                                  <input type="text" class="form-control" v-model="fontColorInput" aria-label="Text input with checkbox">
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_secret">Secondary Font Color:</label>
                              <div class="col-sm-7">

                                <div class="input-group mb-3">
                                  <div class="input-group-prepend">
                                    <div class="dash-input-group-text">
                                      <input type="color" id="color" v-model="descriptionFontColor">
                                    </div>
                                  </div>
                                  <input type="text" class="form-control" v-model="descriptionFontColorInput" aria-label="Text input with checkbox">
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div>
                          <div class="setting_header">
                            <h2>Typography</h2>
                          </div>
                          <div class="form-group setting_form_grp mt-4">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type">Title Style:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="productsTitle">
                                  <option value="uppercase">Uppercase</option>
                                  <option value="capitalize">Capitalize</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp mt-4">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type">Font Option:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="font">
                                  <option value="google">Google Fonts</option>
                
                                </select>
                              </div>
                            </div>
                          </div>
                          <div>
                            
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="mbl_type">Font Name:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="fontName" class="form-control" placeholder="Font name">
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="mbl_type">Google Font Link:</label>
                                <div class="col-sm-7">
                                  <input type="text" v-model="googleLink" class="form-control" placeholder="Google Font Link">
                                </div>
                              </div>
                            </div>

                          </div>
                          <div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="mbl_type">Use Custom Fonts:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="checkbox" v-model="customFont" :value="1" :checked="customFont == 1? true : false"></label> &nbsp;&nbsp;
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <div>
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="mbl_type">Select Custom Font:</label>
                                <div class="col-sm-7">
                                  <select class="form-control" id="" v-model="customfontName">
                                    <option value="">--select font--</option>
                                    <option value="gotham">Gotham</option>
                                    <option value="boycottregular">Boycottregular</option>
                                    <option value="alpha_echoregular">Alpha_echoregular</option>
                                    <option value="surveyortextbook">Surveyortextbook</option>
                                    <option value="cocogoose">Cocogoose</option>
                                    <option value="surveyorfine">Surveyorfine</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div>
                          <div class="setting_header">
                            <h2>Ordering Page</h2>
                          </div>
                          <div class="form-group setting_form_grp mt-4">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type">Theme:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="theme">
                                  <option value="1">theme 1</option>
                                  <option value="2">theme 2</option>
                                  <option value="3">theme 3</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type">Theme Options:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="orderingOption">
                                  <option value="1">Option 1</option>
                                  <option value="2">Option 2</option>
                                  <option value="3">Option 3</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type"> Order Layout:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="orderingOption">
                                  <option value="1">Option 1</option>
                                  <option value="2">Option 2</option>
                                  <option value="3">Option 3</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_secret"> Show Images:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="showProdImages" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="showProdImages" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <!--  -->
                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="pwd"> Show Category Image:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="showCatImages" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="showCatImages" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type"> Cart Option:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="cartOption">
                                  <option value="right">Right</option>
                                  <option value="bottom">Bottom</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type"> Category List:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="categorylisting">
                                  <option value="left">Left</option>
                                  <option value="top">Top</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type"> Cards per Row:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="cardsNumber">
                                  <option :value="shelve" v-for="shelve in shelves" :key="shelve">{{shelve}}</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Add Card Border:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="cardBorder" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="cardBorder" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Allow Padding on Image:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="imagePadding" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="imagePadding" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Allow Images To be Cropped:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="imageCroped" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="imageCroped" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Show description:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="showDescription" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="showDescription" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type"> Card Content alignment:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="contentAlignment">
                                  <option value="left">left</option>
                                  <option value="center">center</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="form-group setting_form_grp">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Footer has Background Color:</label>
                              <div class="col-sm-7">
                                <label class="radio-inline"><input type="radio" v-model="coloredFooter" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                <label class="radio-inline"><input type="radio" v-model="coloredFooter" :value="0"> &nbsp; No</label>
                              </div>
                            </div>
                          </div>

                          <!-- end div setup -->
                          <!-- div live setup -->

                        </div>

                     



                        <div>
                        </div>

                      </div>
                      <!--  -->
                      <div class="col-md-6">
                        <div>
                          <div class="setting_header">
                            <h2>Voucher Page</h2>
                          </div>
                          <div class="form-group setting_form_grp mt-4">
                            <div class="row">
                              <label class="control-label col-sm-5 text-right" for="mbl_type">Theme:</label>
                              <div class="col-sm-7">
                                <select class="form-control" v-model="voucherTheme">
                                  <option value="1">Multiple Layout</option>
                                  <option value="2">Single Layout </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <!-- end div setup -->
                          <!-- div live setup -->

                        </div>
                        <div>
                          <div class="setting_header">
                            <h2>Home page</h2>
                          </div>
                          <div class="setting_content">
                            <div class="form-group setting_form_grp mt-4">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="page_title">Homepage Option:</label>
                                <div class="col-sm-7">
                                  <select class="form-control" v-model="homepageOption">
                                    <option value="1">Image on Left</option>
                                    <option value="2">Image on Right</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="page_title">Splash Screen Text:</label>
                                <div class="col-sm-7">
                                  <select class="form-control" v-model="splashScreen">
                                    <option value="left">on Left</option>
                                    <option value="center">on Center</option>
                                    <option value="right">on Right</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Show Category description:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="homepageDescription" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="homepageDescription" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Show HomePage:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="enableHomePage" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="enableHomePage" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>

                            <div class="form-group setting_form_grp">
                              <div class="row">
                                <label class="control-label col-sm-5 text-right" for="lv_consumer_key">Show Menu:</label>
                                <div class="col-sm-7">
                                  <label class="radio-inline"><input type="radio" v-model="viewMenu" :value="1">&nbsp; Yes </label> &nbsp;&nbsp;
                                  <label class="radio-inline"><input type="radio" v-model="viewMenu" :value="0"> &nbsp; No</label>
                                </div>
                              </div>
                            </div>

                            


                            <div id="foodie-loading-cover" class="pl-5 pr-4 pt-4 mb-4">
                              <span>
                                <label data-baseweb="form-control-label">page Loader</label>
                              </span>
                              <div data-baseweb="form-control" class="al dw">
                                <div class="image_div">
                                  <div class="img_preview" style="">
                                    <div tabindex="0" style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                                      <img class="image_responsive" v-if="images" :src="images" />
                                    </div>
                                  </div>
                                  <div class="image_content">
                                    <div class="image_button">

                                      <div class="add_image_btn_grp">
                                        <div class="btn_plus_icon">
                                        
                                          <input id='fileid' type='file' @change="uploadImage($event, 2484, 1380)" hidden />
                                          <i class="fa fa-plus" style='font-size:24px' @click="openFileUploadDialog()"></i>
                                        </div>
                                        <div class="btn_url">
                                          <span>Add from URL</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div id="foodie-loader"> </div>
                            </div>

                            <hr>

                            <div  class="pl-5 pr-4 pt-4 mb-4">
                              <span>
                                <label data-baseweb="form-control-label pb-5">Homepage Description</label>
                              </span>
                              <div data-baseweb="form-control" class="mt-4 al dw">
                                <div class="image_div">
                                  <editor v-model="homepageDesc" :init="{
                                    height: 300,
                                    branding: false
                                  }" />                                  
                                </div>
                              </div>
                            </div>
                            <!--  -->
                            <hr>
                            <div id="foodie-loading-cover" class="pl-5 pr-4 pt-4 mb-4">
                              <span>
                                <label data-baseweb="form-control-label pb-5">Image sliders</label>
                              </span>
                              <div class="row mt-4 mb-4">
                                <div class="col-md-6" v-for="(imageslide,index) in imageSliders" :key="index">
                                  <div class="item_card h-100">
                                    <div tabindex="0" style="display: flex; justify-content: center; width: 100%; height: 100%; border: 2px dashed rgb(229, 229, 229); background: rgb(247, 247, 247) none repeat scroll 0% 0%; flex-direction: column; align-items: center; padding: 20px 0px; box-sizing: border-box;">
                                      <span class="float-right remove_icon" @click="removeSlide(index)" aria-hidden="true">&times;</span>
                                      <img class="image_responsive" v-if="imageSliders[index]" :src="imageSliders[index]" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div >
                                <div class="image_button">

                                  <div class="add_image_btn_grp">
                                    <div class="btn_plus_icon">
                                      <input id='multifileid' type='file' @change="uploadSlider($event, 2484, 1380)" hidden />
                                      <i class="fa fa-plus" style='font-size:24px' @click="openFileUploadDialog('multifileid')"></i>
                                    </div>
                                    <div class="btn_url" @click="openFileUploadDialog('multifileid')">
                                      <span>Add from URL</span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <button type="submit" class="btn btn-orange btn-lg"><span v-if="spinner" v-html="searchspinner"></span> <span v-else>Save Changes</span></button>
                      </div>
                    </div>
                    <!-- end row -->
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
const toast = useToast();

import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        Editor,
    },
    data() {
        return {
            loading: false,
            homepageOption: 1,
            splashScreen: 'left',
            orderingOption: 1,
            showProdImages: 1,
            showCatImages: 1,
            cardsNumber: 3,
            categorylisting: 'top',
            cartOption: 'right',
            cardBorder: 1,
            imagePadding: 0,
            showDescription: 0,
            contentAlignment: 'left',
            theme: 1,
            coloredFooter: 1,
            homepageDescription: 0,
            errors: false,
            errorMsg: [],
            mainColor: '#ffffff',
            mainColorInput: '#ffffff',
            spinner: false,
            searchspinner: "<i class='fa fa-spinner fa-spin '></i> Saving Changes...",
            shelves: 3,
            config: [],
            enableHomePage: 1,
            imageUrl: '',
            images: null,
            font: 'google',
            fontName: '',
            customFont: 0,
            customfontName:'',
            primaryColor: '#FF4F22',
            primaryColorInput: '#FF4F22',
            secondaryColor: '',
            secondaryColorInput: '',
            fontColor: '#000000',
            fontColorInput: '#000000',
            productsTitle: 'uppercase',
            descriptionFontColor: '#706d61',
            descriptionFontColorInput: '#706d61',
            viewMenu:1,
            googleLink:'',
            imageCroped: 1,
            homepageDesc:'',
            imageSliders:[],
            slide:'',
            voucherTheme:1
        }
    },

    mounted() {
        this.getLayoutConfigs();
    },
    watch: {
        categorylisting() {
            if (this.categorylisting == 'left' && this.cartOption == 'right') {
                this.shelves = 2;
                this.cardsNumber = this.shelves;
            } else {
                this.shelves = 3;
            }
        },
        cartOption() {
            if (this.categorylisting == 'left' && this.cartOption == 'right') {
                this.shelves = 2;
            } else {
                this.shelves = 3;
            }
        },
        fontColorInput() {
            this.fontColor = this.fontColorInput
        },
        secondaryColorInput() {
            this.secondaryColor = this.secondaryColorInput
        },
        primaryColorInput() {
            this.primaryColor = this.primaryColorInput
        },
        mainColorInput() {
            this.mainColor = this.mainColorInput
        },
        descriptionFontColorInput(){
          this.descriptionFontColor = this.descriptionFontColorInput
        },
        descriptionFontColor(){
          this.descriptionFontColorInput = this.descriptionFontColor
        },
        mainColor() {
            this.mainColorInput = this.mainColor
        },
        primaryColor() {
            this.primaryColorInput = this.primaryColor
        },
        secondaryColor() {
            this.secondaryColorInput = this.secondaryColor
        },
        fontColor() {
            this.fontColorInput = this.fontColor
        }

    },
    methods: {
      removeSlide(index){
        this.imageSliders.splice(index,1)
      },
      async uploadSlider(event, width, height, type = '') {
            const files = event.target.files || event.dataTransfer.files;
            if (!files.length) {
                toast.error("file too large");
                return;
            }
            const allowedExtensions =
                /(\.jpg|\.jpeg|\.jpe|\.svg|\.png|\.gif|\.WEBP)$/i;
            const file = files[0];
            const fileName = file.name
            if (!allowedExtensions.exec(fileName)) {
                toast.error("cannot support the file type please use jpg,jpeg,png,gif or WEBP");
                return false;
            }
            const imageSize = file.size / 1000000
            if (type === 'item' && imageSize > 3) {
                toast.error("file too large")
                return
            }
            const reader = new FileReader();
            const dataURL = await new Promise(resolve => {
                reader.onload = (e) => resolve(e.target.result);
                reader.readAsDataURL(file);
            });
            const img = new Image();

            await new Promise(resolve => {
                img.onload = resolve;
                img.src = dataURL;
            });
            // Resize the image with a canvas
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            // This is an addition to keep the aspect ratio of the image so it won't get distorted
            const [imgWidth, imgHeight] = [
                img.naturalWidth,
                img.naturalHeight
            ];
            let nWidth = imgWidth
            let nHeight = imgHeight
            if (imgWidth > width) {
                const ratio = width / imgWidth;
                nWidth = imgWidth * ratio;
                nHeight = imgHeight * ratio;
            }
            if (imgHeight > height) {
                const ratio = height / imgHeight;
                nWidth = imgWidth * ratio;
                nHeight = imgHeight * ratio;
            }
            canvas.width = nWidth;
            canvas.height = nHeight;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const images = canvas.toDataURL('image/png', 0.9);

            const binary = atob(images.split(',')[1]);
            const array = [];
            for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            const blobData = new Blob([new Uint8Array(array)], { type: 'image/png' });

            const formData = new FormData();

            formData.append('image_file', blobData);
            const config = this.apiRequestConfig({
                'content-type': 'multipart/form-data'
            });
            const url = `${this.baseUrl}/add_image`;
            this.axios.post(url, formData, config).then((response) => {
                if (response.status === 200) {
                    this.imageSliders.push(response.data.image_url);
                }
            })
                .catch((error) => {
                    if (error.response) {
                        toast.error('Code: ' + error.response.status + ', message: ' + error.response.data.message);
                    }
                })
        },
        async createLayoutConfigs() {
            this.spinner = true;
            const url = this.dashBaseUrl + "/create-layout-settings";
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
           
            const payload = {
                'homepage_option': this.homepageOption,
                'splash_text': this.splashScreen,
                'ordering_option': this.orderingOption,
                'show_product_image': this.showProdImages,
                'show_category_image': this.showCatImages,
                'shelves_number': this.cardsNumber,
                'category_listing': this.categorylisting,
                'shopping_cart': this.cartOption,
                'merchant_id': parseInt(this.merchantID),
                'card_border': this.cardBorder,
                'allow_image_padding': this.imagePadding,
                'show_description': this.showDescription,
                'card_description_align': this.contentAlignment,
                'colored_footer': this.coloredFooter,
                'theme': this.theme,
                'main_color': this.mainColor,
                'show_homepage_description': this.homepageDescription,
                'show_homepage': this.enableHomePage,
                'page_loader': this.imageUrl,
                'primary_color': this.primaryColor,
                'font_color': this.fontColor,
                'secondary_color': this.secondaryColor,
                'font': this.font,
                'font_name': this.fontName,
                'description_font_color': this.descriptionFontColor,
                'products_title': this.productsTitle,
                'view_menu': this.viewMenu,
                'google_fonts_link': this.googleLink,
                'allow_custom_fonts': this.customFont,
                'custom_font_name': this.customfontName,
                'allow_croped_images':this.imageCroped,
                'homepage_description':this.homepageDesc,
                'image_sliders':this.imageSliders,
                'voucher_theme': this.voucherTheme

            }
            this.axios.post(url, payload, config).then((response) => {
                if (response.status === 200) {
                    toast.success('Change on App config was successful');
                    this.getLayoutConfigs();
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    this.errors = true;
                    this.errorMsg = error.response.data.errors
                } else if (error.response.status === 401) {
                    toast.error('session has expired');
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => this.spinner = false);
        },
        async getLayoutConfigs() {
            this.loading = true;
            const loader = document.getElementById('main-loader');
            loader.style.display = 'block';

            const url = `${this.dashBaseUrl}/layout-settings/${this.merchantID}`
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            this.axios.get(url, config).then((response) => {
                if (response.status === 200 && response.data.data) {
                    this.homepageOption = response.data.data.homepage_option;
                    this.splashScreen = response.data.data.splash_text;
                    this.orderingOption = response.data.data.ordering_option;
                    this.showProdImages = response.data.data.show_product_image;
                    this.showCatImages = response.data.data.show_category_image;
                    this.cardsNumber = response.data.data.shelves_number;
                    this.categorylisting = response.data.data.category_listing;
                    this.cartOption = response.data.data.shopping_cart;
                    this.cardBorder = response.data.data.card_border;
                    this.imagePadding = response.data.data.allow_image_padding;
                    this.showDescription = response.data.data.show_description;
                    this.contentAlignment = response.data.data.card_description_align;
                    this.coloredFooter = response.data.data.colored_footer;
                    this.homepageDescription = response.data.data.show_homepage_description;
                    this.theme = response.data.data.theme;
                    this.mainColor = response.data.data.background_color ? response.data.data.background_color : '#ffffff';
                    this.enableHomePage = response.data.data.show_homepage;
                    this.imageUrl = response.data.data.page_loader;
                    this.primaryColor = response.data.data.primary_color ? response.data.data.primary_color : '#FF4F22';
                    this.fontColor = response.data.data.font_color ? response.data.data.font_color : '#000000';
                    this.secondaryColor = response.data.data.secondary_color;
                    this.font = response.data.data.font
                    this.fontName = response.data.data.font_name;
                    this.images = this.imageUrl;
                    this.viewMenu = response.data.data.view_menu;
                    this.googleLink =  response.data.data.google_fonts_link;
                    this.customFont = response.data.data.allow_custom_fonts;
                    this.customfontName =  response.data.data.custom_font_name ? response.data.data.custom_font_name : '';
                    this.homepageDesc = response.data.data.homepage_description
                    this.imageSliders = response.data.data.image_sliders ? JSON.parse(response.data.data.image_sliders) :[];
                    this.voucherTheme = response.data.data.voucher_theme ? response.data.data.voucher_theme : 1
                }
            }).catch((error) => {
                if (error.response.status === 401) {
                    this.$router.push({
                        name: 'login'
                    });
                }
            }).finally(() => {
                this.loading = false;
                loader.style.display = 'none';
            });
        }
    },
    mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
